import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import SignupDialog from "./pages/auth/Signup";
import LandingTourism from "./pages/landing/LandingTourism";
import BannerWidgetShowcase from './pages/WidgetShowcase/BannerWidgetShowcase';
import ButtonWidgetShowcase from "./pages/WidgetShowcase/ButtonWidgetShowcase";
import ContainerWidgetShowcase from "./pages/WidgetShowcase/ContainerWidgetShowcase";
import PopupWidgetShowcase from "./pages/WidgetShowcase/PopupWidgetShowcase";
import SideWidgetShowcase from "./pages/WidgetShowcase/SideWidgetShowcase";
import WidgetShowcase from "./pages/WidgetShowcase/WidgetShowcase";


function App() {
    const location = useLocation();

    if (location.state?.redirectTo) return (
        <Navigate
            to={location.state.redirectTo}
            replace
            state={{ backgroundLocation: location }}
        />
    );

    return (
        <>
            {location.state?.backgroundLocation &&
                <Routes>
                    <Route path="/signup" element={<SignupDialog />} />
                </Routes>
            }
            <Routes location={location.state?.backgroundLocation || location}>
                <Route path="/" element={<LandingTourism />} />
                <Route path="/widgets" element={<WidgetShowcase />} />
                <Route path="/widgets/container" element={<ContainerWidgetShowcase />} />
                <Route path="/widgets/banner" element={<BannerWidgetShowcase />} />
                <Route path="/widgets/button" element={<ButtonWidgetShowcase />} />
                <Route path="/widgets/popup" element={<PopupWidgetShowcase />} />
                <Route path="/widgets/side" element={<SideWidgetShowcase />} />
                <Route path="/signup" element={<Navigate to="/" replace state={{ redirectTo: "/signup" }} />} />
            </Routes>
        </>
    );
}

export default App;
