import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import dashRight from "../../images/dashRight.svg";
import dashLeft from "../../images/leftDash.svg";
import dashRightTop from "../../images/rightDash.svg";
import dashRightPlane from "../../images/rightDashPlane.svg";
import Section from "../../kit/section";
import QuizContainer from "./QuizContainer";

export default function QuizInteractive() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(600));
    const isTablet = useMediaQuery(theme.breakpoints.down(1000));

    return (
        <Section
            tag={"section"}
            bg={"#F2F3F7"}
            sxsect={{
                // minHeight: "7049px",
                marginTop: isMobile ? "-65px" : "-100px",
                alignItems: "flex-start",
                zIndex: 2,
                position: "relative",
                borderRadius: isMobile ? "30px 30px 0 0" : "80px 80px 0 0"
            }}
            mwidth={"1160px"}
            sxcont={{
                padding: isMobile
                    ? "80px 16px 44px 16px"
                    : isTablet
                        ? "125px 40px 0 40px"
                        : "130px 0px 0 0px",
                position: "relative",
                boxSizing: "border-box",
            }}
        >
            <Box
                color="#ffffff"
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    marginBottom: isMobile ? "10px" : "65px",
                    gap: isMobile ? "55px" : "60px",
                }}
            >
                <Box>
                    <Typography variant="h6" fontSize="36px" color={"#000000"} maxWidth={"500px"} lineHeight={"normal"}>
                        Как будут выглядеть квизы на вашем сайте
                    </Typography>
                    <Typography fontSize="18px" color={"#4D4D4D"} marginTop={"20px"}>Интерактивные</Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: isMobile ? "70px" : "140px"
                    }}
                >
                    <QuizBox zIndex={9}>
                        <Box
                            component={"img"}
                            src={dashRightTop}
                            sx={{
                                position: "absolute",
                                top: isMobile ? "-159px" : "-316px",
                                right: isMobile ? "-72px" : isTablet ? "-19px" : "-68px",
                                width: isMobile ? "70%" : undefined
                            }}
                        />
                        <QuizContainer elementId="quiz-1" quizId="0c17353b-55f9-44b5-91d4-a7ffebc5897f" />
                    </QuizBox>
                    <QuizBox zIndex={8}>
                        <Box
                            component={"img"}
                            src={dashLeft}
                            sx={{
                                position: "absolute",
                                zIndex: 1,
                                top: isMobile ? "-134px" : isTablet ? "-277px" : "-282px",
                                left: isMobile ? "32px" : isTablet ? "49px" : "-97px",
                                width: isMobile ? "70%" : undefined
                            }}
                        />
                        <QuizContainer elementId="quiz-2" quizId="81e3751b-21da-4788-9a66-bff7204d619c" />
                    </QuizBox>
                    <QuizBox zIndex={7}>
                        <Box
                            component={"img"}
                            src={dashRightPlane}
                            sx={{
                                position: "absolute",
                                top: isMobile ? "-133px" : isTablet ? "-277px" : "-282px",
                                right: isMobile ? "24px" : isTablet ? "51px" : "-97px",
                                width: isMobile ? "70%" : undefined
                            }}
                        />
                        <QuizContainer elementId="quiz-3" quizId="8d955f65-728a-40e4-a8f6-6c17e78d193b" />
                    </QuizBox>
                    <QuizBox zIndex={6}>
                        <Box
                            component={"img"}
                            src={dashLeft}
                            sx={{
                                position: "absolute",
                                top: isMobile ? "-134px" : isTablet ? "-277px" : "-282px",
                                left: isMobile ? "32px" : isTablet ? "49px" : "-97px",
                                width: isMobile ? "70%" : undefined
                            }}
                        />
                        <QuizContainer elementId="quiz-4" quizId="e0f23ed5-a3b5-4c42-9bbb-18df97a68ec8" />
                    </QuizBox>
                    <QuizBox zIndex={5}>
                        <Box
                            component={"img"}
                            src={dashRight}
                            sx={{
                                position: "absolute",
                                top: isMobile ? "-134px" : "-281px",
                                right: isTablet ? "50px" : "-96px",
                                width: isMobile ? "70%" : undefined
                            }}
                        />
                        <QuizContainer elementId="quiz-5" quizId="46c8705e-8938-49d9-bea3-a6bd0557b9f1" />
                    </QuizBox>
                    {/* <QuizBox zIndex={4}>
                        <Typography>interactive</Typography>
                        <Box
                            component={"img"}
                            src={dashLeftPlane}
                            sx={{
                                position: "absolute",
                                top: isMobile ? "-134px" : isTablet ? "-277px" : "-282px",
                                left: isMobile ? "31px" : isTablet ? "50px" : "-97px",
                                width: isMobile ? "70%" : undefined
                            }}
                        />
                    </QuizBox>
                    <QuizBox zIndex={3}>
                        <Typography>interactive</Typography>
                        <Box
                            component={"img"}
                            src={dashRight}
                            sx={{
                                position: "absolute",
                                top: isMobile ? "-134px" : "-281px",
                                right: isTablet ? "50px" : "-96px",
                                width: isMobile ? "70%" : undefined
                            }}
                        />
                    </QuizBox>
                    <QuizBox zIndex={2}>
                        <Typography>interactive</Typography>
                        <Box
                            component={"img"}
                            src={dashLeft}
                            sx={{
                                position: "absolute",
                                top: isMobile ? "-134px" : isTablet ? "-277px" : "-282px",
                                left: isMobile ? "32px" : isTablet ? "49px" : "-97px",
                                width: isMobile ? "70%" : undefined
                            }}
                        />
                    </QuizBox>
                    <QuizBox zIndex={1}>
                        <Typography>interactive</Typography>
                        <Box
                            component={"img"}
                            src={dashRightPlane}
                            sx={{
                                position: "absolute",
                                top: isMobile ? "-133px" : isTablet ? "-277px" : "-282px",
                                right: isMobile ? "24px" : isTablet ? "51px" : "-97px",
                                width: isMobile ? "70%" : undefined
                            }}
                        />
                    </QuizBox> */}
                </Box>
            </Box>
        </Section>
    );
}

interface Props {
    children: React.ReactNode,
    zIndex?: number;
}

function QuizBox(props: Props) {
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down(1000));
    const isMobile = useMediaQuery(theme.breakpoints.down(600));

    return (
        <Box
            sx={{
                width: "100%",
                maxWidth: "1160px",
                height: isMobile ? "634px" : isTablet ? "491px" : "608px",
                borderRadius: "12px",
                backgroundColor: "#FFFFFF",
                position: "relative",
                zIndex: props.zIndex,
                boxShadow: "0px 2.767256498336792px 8.550822257995605px 0px #D2D0E111",
                overflow: "hidden",
                color: "black"

            }}
        > {props.children}</Box>
    );
}
