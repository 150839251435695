import { makeRequest } from "@frontend/kitui";

import type {
    RegisterRequest,
    RegisterResponse,
} from "@frontend/kitui";
import { parseAxiosError } from "../utils/parse-error";

const apiUrl = "https://quiz.pena.digital/auth";

export async function register(
    login: string,
    password: string,
    phoneNumber: string,
): Promise<[RegisterResponse | null, string?]> {
    try {
        const registerResponse = await makeRequest<
            RegisterRequest,
            RegisterResponse
            >({
            url: apiUrl + "/register",
            body: { login, password, phoneNumber },
            useToken: false,
            withCredentials: true,
        });
        return [registerResponse];
    } catch (nativeError) {
        const [error] = parseAxiosError(nativeError);

        return [null, `Не удалось зарегистрировать аккаунт. ${error}`];
    }
}
