import { FC, SVGProps } from "react";

export const Logo: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg
        {...props}
        viewBox="0 0 149 58"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_1_21)">
            <path fillRule="evenodd" clipRule="evenodd" d="M21.5086 2.75764C15.4327 2.05384 11.241 8.58037 7.34204 13.2981C3.92034 17.4383 1.37259 22.1148 1.07666 27.4802C0.761826 33.1885 1.79731 39.1334 5.6862 43.32C9.69195 47.6324 15.6924 50.3571 21.5086 49.4762C26.8418 48.6684 29.2017 42.8117 33.1723 39.1568C37.4837 35.1882 45.516 33.3424 45.4046 27.4802C45.293 21.609 36.8157 20.4597 32.7367 16.2397C28.5117 11.8685 27.5442 3.45677 21.5086 2.75764Z" fill="#7E2AEA"/>
            <circle cx="36.6272" cy="47.2429" r="3.3526" fill="#7E2AEA"/>
            <circle cx="33.2743" cy="10.0479" r="1.27718" fill="#7E2AEA"/>
            <path d="M53.7046 26.1072C53.3322 22.4867 51.6289 19.1334 48.9248 16.6973C46.2208 14.2612 42.7085 12.9158 39.069 12.9218C38.5522 12.9221 38.0358 12.9491 37.5219 13.0028C33.903 13.3828 30.5531 15.0894 28.1181 17.7933C25.6831 20.4973 24.3356 24.0071 24.3355 27.6458V27.6458V48.6801H30.6458V39.7405C33.113 41.457 36.0476 42.3747 39.0532 42.3698C39.5699 42.3697 40.0863 42.3426 40.6002 42.2888C42.5233 42.0867 44.3877 41.5078 46.087 40.5852C47.7863 39.6626 49.2872 38.4143 50.504 36.9115C51.7209 35.4088 52.6299 33.6812 53.179 31.8272C53.7282 29.9732 53.9068 28.0292 53.7046 26.1061V26.1072ZM45.6001 32.9433C44.9069 33.8044 44.0498 34.5194 43.0784 35.0469C42.1069 35.5744 41.0405 35.9039 39.9408 36.0164C39.6459 36.0471 39.3497 36.0625 39.0532 36.0627C37.1448 36.0609 35.2937 35.4105 33.8037 34.218C32.3137 33.0256 31.2734 31.362 30.8534 29.5004C30.4335 27.6388 30.6589 25.6897 31.4927 23.9731C32.3265 22.2565 33.7191 20.8743 35.4419 20.0534C37.1647 19.2325 39.1154 19.0217 40.9739 19.4556C42.8323 19.8895 44.488 20.9423 45.6692 22.4412C46.8504 23.9401 47.487 25.796 47.4745 27.7044C47.4619 29.6127 46.8009 31.4601 45.6001 32.9433Z" fill="currentColor"/>
            <path d="M70.1684 12.9218C69.6516 12.9221 69.1352 12.9491 68.6213 13.0028C64.8716 13.3977 61.4163 15.216 58.9675 18.083C56.5187 20.95 55.263 24.6472 55.4594 28.4125C55.6557 32.1779 57.2891 35.7244 60.0227 38.3212C62.7564 40.9179 66.3821 42.367 70.1526 42.3698C70.6693 42.3696 71.1857 42.3426 71.6997 42.2888C74.4032 42.001 76.9749 40.9725 79.1314 39.3167C81.2879 37.6609 82.9455 35.442 83.9216 32.9044H76.729L76.6995 32.9412C75.7782 34.08 74.5755 34.9585 73.2105 35.4899C71.8455 36.0212 70.3654 36.187 68.9166 35.9708C67.4678 35.7546 66.1006 35.164 64.9502 34.2572C63.7998 33.3505 62.906 32.1591 62.3573 30.801H84.5484C85.0157 28.6512 84.996 26.4241 84.4909 24.2829C83.9858 22.1417 83.008 20.1407 81.6292 18.4264C80.2504 16.7121 78.5056 15.328 76.5225 14.3756C74.5394 13.4231 72.3683 12.9264 70.1684 12.9218V12.9218ZM62.3573 24.4907C62.9317 23.0767 63.8791 21.8452 65.0983 20.9272C66.3176 20.0093 67.7631 19.4395 69.2807 19.2784C69.5756 19.2478 69.8719 19.2323 70.1684 19.2321C71.8444 19.2293 73.4829 19.7288 74.8723 20.6661C76.2618 21.6034 77.3386 22.9355 77.9637 24.4907H62.3573Z" fill="currentColor"/>
            <path d="M100.136 12.9218C97.7245 12.918 95.3586 13.5812 93.3002 14.8381V12.9218H86.9899V42.3698H93.3002V26.0683C93.3002 24.2552 94.0204 22.5164 95.3024 21.2344C96.5844 19.9524 98.3232 19.2321 100.136 19.2321C101.949 19.2321 103.688 19.9524 104.97 21.2344C106.252 22.5164 106.972 24.2552 106.972 26.0683V42.3698H113.283V26.0683C113.283 22.5816 111.898 19.2378 109.432 16.7723C106.967 14.3069 103.623 12.9218 100.136 12.9218Z" fill="currentColor"/>
            <path d="M144.835 29.5274V12.9219H138.525V15.5511C136.058 13.834 133.123 12.9161 130.118 12.9219C129.601 12.9221 129.084 12.9491 128.571 13.0028C124.821 13.3977 121.365 15.2161 118.917 18.0831C116.468 20.9501 115.212 24.6472 115.409 28.4126C115.605 32.1779 117.238 35.7244 119.972 38.3212C122.706 40.918 126.331 42.3671 130.102 42.3698C130.619 42.3696 131.135 42.3426 131.649 42.2889C135.316 41.9135 138.707 40.1663 141.141 37.3973C143.032 39.9275 145.771 41.6925 148.856 42.3698V35.7546C147.659 35.2123 146.643 34.3366 145.93 33.2323C145.217 32.1281 144.837 30.8418 144.835 29.5274ZM136.649 32.9412C135.955 33.8021 135.098 34.517 134.127 35.0445C133.155 35.572 132.089 35.9016 130.989 36.0143C130.695 36.045 130.398 36.0604 130.102 36.0606C127.946 36.0605 125.872 35.2328 124.309 33.7484C122.745 32.2639 121.811 30.2359 121.7 28.0829C121.588 25.9299 122.307 23.8161 123.708 22.1778C125.11 20.5395 127.087 19.5016 129.231 19.2784C129.526 19.2478 129.821 19.2323 130.118 19.2321C131.706 19.2335 133.262 19.6845 134.605 20.5331C135.948 21.3816 137.023 22.593 137.706 24.0271C138.389 25.4613 138.653 27.0595 138.466 28.637C138.278 30.2145 137.649 31.7068 136.649 32.9412Z" fill="currentColor"/>
            <path d="M123.338 57.5645V54.1748C123.106 54.4753 122.816 54.7144 122.469 54.8921C122.122 55.0656 121.747 55.1523 121.345 55.1523C120.579 55.1523 119.949 54.8646 119.454 54.2891C118.87 53.6162 118.578 52.7043 118.578 51.5532C118.578 50.4699 118.851 49.6257 119.396 49.0205C119.947 48.4111 120.628 48.1064 121.44 48.1064C121.889 48.1064 122.276 48.2017 122.602 48.3921C122.932 48.5825 123.224 48.8703 123.478 49.2554V48.2588H125.122V57.5645H123.338ZM123.396 51.5469C123.396 50.8571 123.254 50.3451 122.97 50.0107C122.691 49.6722 122.34 49.5029 121.917 49.5029C121.485 49.5029 121.123 49.6743 120.831 50.0171C120.543 50.3599 120.399 50.9036 120.399 51.6484C120.399 52.389 120.539 52.9243 120.818 53.2544C121.098 53.5802 121.443 53.7432 121.853 53.7432C122.264 53.7432 122.623 53.5591 122.932 53.1909C123.241 52.8228 123.396 52.2747 123.396 51.5469ZM131.317 55V53.9907C131.072 54.3504 130.748 54.634 130.346 54.8413C129.948 55.0487 129.527 55.1523 129.083 55.1523C128.63 55.1523 128.224 55.0529 127.864 54.854C127.505 54.6551 127.244 54.3758 127.083 54.0161C126.923 53.6564 126.842 53.1592 126.842 52.5244V48.2588H128.626V51.3564C128.626 52.3044 128.658 52.8862 128.721 53.1021C128.789 53.3136 128.91 53.4829 129.083 53.6099C129.257 53.7326 129.477 53.7939 129.743 53.7939C130.048 53.7939 130.321 53.7114 130.562 53.5464C130.803 53.3771 130.968 53.1698 131.057 52.9243C131.146 52.6746 131.19 52.0674 131.19 51.1025V48.2588H132.974V55H131.317ZM134.828 47.3447V45.6943H136.611V47.3447H134.828ZM134.828 55V48.2588H136.611V55H134.828ZM137.729 55V53.6099L140.255 50.709C140.67 50.235 140.976 49.8986 141.175 49.6997C140.968 49.7124 140.695 49.7209 140.356 49.7251L137.976 49.7378V48.2588H143.549V49.522L140.972 52.4927L140.064 53.4766C140.56 53.4469 140.866 53.4321 140.985 53.4321H143.746V55H137.729Z" fill="currentColor"/>
        </g>
        <defs>
            <clipPath id="clip0_1_21">
                <rect width="149" height="57.6774" fill="currentColor"/>
            </clipPath>
        </defs>
    </svg>
);
