import React from 'react';
import Header from "../../kit/Header";
import Hero from "./hero";
import Footer from "../../kit/footer";
import QuizInteractive from "./quizInteractive";


export default function LandingTourism() {
    return (
        <div style={{overflow: "hidden"}}>
            <Header/>
            <Hero/>
            <QuizInteractive/>
            <Footer/>
        </div>
    );
}

