import type { AxiosError } from "axios";

export type ServerError = {
    statusCode: number;
    error: string;
    message: string;
};

const translateMessage: Record<string, string> = {
    "user not found": "Пользователь не найден",
    "invalid password": "Неправильный пароль",
    "field <password> is empty": 'Поле "Пароль" не заполнено',
    "field <login> is empty": 'Поле "Логин" не заполнено',
    "field <email> is empty": 'Поле "E-mail" не заполнено',
    "field <phoneNumber> is empty": 'Поле "Номер телефона" не заполнено',
    "user with this email or login is exist": "Пользователь уже существует",
    "user with this login is exist":
        "Пользователь с таким логином уже существует",
};

export const parseAxiosError = (nativeError: unknown): [string, number?] => {
    const error = nativeError as AxiosError;
    if (error.response?.data) {
        const serverError = error.response.data as ServerError;
        let SEMessage;
        if ("statusCode" in (error.response?.data as ServerError)) {
            SEMessage = serverError?.message.toLowerCase() || "";
        }
        if (
            "error" in (error.response?.data as ServerError) &&
            !("statusCode" in (error.response?.data as ServerError))
        ) {
            SEMessage = serverError?.error.toLowerCase() || "";
        }
        console.log(serverError);
        const translatedMessage = translateMessage[SEMessage || ""];
        if (translatedMessage !== undefined) SEMessage = translatedMessage;
        return [SEMessage || "", serverError.statusCode];
    }

    switch (error.status) {
        case 404:
            return ["Не найдено.", error.status];

        case 403:
            return ["Доступ ограничен.", error.status];

        case 401:
            return ["Ошибка авторизации.", error.status];

        case 500:
            return ["Внутренняя ошибка сервера.", error.status];

        case 503:
            return ["Сервис недоступен.", error.status];

        default:
            return ["Неизвестная ошибка сервера."];
    }
};
