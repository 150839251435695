import React from "react";
import Section from "./section";
import {IconButton, useMediaQuery, useTheme} from "@mui/material";
import {Logo} from "../images/logo";
import {Burger} from "../images/Burger";

export default function Header() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(600));
    const isTablet = useMediaQuery(theme.breakpoints.down(1000));

    return (
        <Section
            tag={"header"}
            bg={"#FFFFFF33"}
            mwidth={"1160px"}
            sxsect={{
                minHeight: isMobile ? "51px" : "80px",
                borderBottom: "1px solid #E3E3E3",
                position: "absolute",
                padding: isMobile ? "0 16px" : isTablet ? "0 40px" : "0 20px",
                zIndex: 3,
            }}
            sxcont={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 0,
            }}
        >
            {/*<Link to="/">*/}
                <Logo width={isMobile ? 100 : 149} color={"white"}/>
            {/*</Link>*/}
            <IconButton>
                <Burger/>
            </IconButton>
        </Section>
    );
}
