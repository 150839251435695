import Section from "../../kit/section";
import BackgroundHero from "../../images/backgroundHero.png"
import {Box, Button, IconButton, Typography, useMediaQuery, useTheme} from "@mui/material";
import plansHero from "../../images/plansHero.svg"
import {VkIcon} from "../../images/vkIcon";
import {WhatsappIcon} from "../../images/whatsappIcon";
import {TelegramIcon} from "../../images/telegramIcon";
import {Link, useLocation} from "react-router-dom";

export default function Hero() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(600));
    const isTablet = useMediaQuery(theme.breakpoints.down(1000));
    const isBigMonitor = useMediaQuery(theme.breakpoints.up(1440));
    const isSmallMonitor = useMediaQuery(theme.breakpoints.down(1200));
    const location = useLocation();
    return(
        <Section
            tag={"section"}
            bg={""}
            mwidth={"1160px"}
            sxsect={{
                minHeight: isMobile ? "766px" : "810px",
                borderBottom: "1px solid #E3E3E3",
                position: "relative",
                alignItems: isMobile ? "flex-start" : "center",
                padding: isMobile ? "118px 16px 0 16px" : isTablet ? "0 40px" : "0 20px",
                backgroundImage: `url(${BackgroundHero})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: isBigMonitor ? "100%" : undefined,
                backgroundPosition: isTablet ? "center" : undefined
            }}
            sxcont={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 0,

            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: isMobile ? "5px" : "30px",
                    height: "100%",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    position: "relative",
                }}
            >
                <Typography variant="h2" color={"white"}>Наименование</Typography>
                <Box
                    sx={{
                        maxWidth: isTablet ? "715px" : "420px",
                        minHeight: "64px",
                    }}
                >
                    <Typography variant="body1" color={"white"} sx={{maxWidth: isTablet ? "426px" : undefined}}>
                        Идейные соображения высшего порядка обуславливают
                        создание системы обучения кадров, соответствует насущным потребностям.
                    </Typography>
                </Box>

                <Button
                    variant="pena-contained-light"
                    component={Link}
                    to={"/signup"}
                    state={{backgroundLocation: location.state}}
                    sx={{
                        marginTop: isMobile ? "35px" : isTablet ? "10px" : undefined,
                        zIndex: 2
                    }}
                >
                   Оставить заявку
                </Button>

                <Box sx={{display: "flex", position: "relative",
                    top: isMobile ? "257px" : "100px",}}>
                    <IconButton><VkIcon/></IconButton>
                    <IconButton><WhatsappIcon/></IconButton>
                    <IconButton><TelegramIcon/></IconButton>
                </Box>

                <Box
                    component={"img"}
                    src={plansHero}
                    sx={{
                        position: "absolute",
                        bottom: isMobile
                            ? undefined
                            : isTablet
                                ? "-133px"
                                : isSmallMonitor
                                    ? "-146px"
                                    : "-116px",
                        maxWidth: isMobile ? "403px" : isTablet ? "425px" : "810px",
                        width: isMobile ? "100%" : undefined,
                        left: isMobile
                            ? "102px"
                            : isTablet
                                ? "564px"
                                : isSmallMonitor
                                    ? "460px"
                                    : "769px",
                        top: isMobile ? "157px" : undefined,
                    }}
                />
            </Box>
        </Section>
    )
}
