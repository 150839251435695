import { Box } from "@mui/material";
import { useEffect } from "react";


interface Props {
    elementId: string;
    quizId: string;
}

export default function QuizContainer({ elementId, quizId }: Props) {

    useEffect(function appendWidgetScript() {
        if (document.querySelector(`#widget-script-${quizId}`)) return;

        const script = document.createElement("script");
        script.setAttribute("id", `widget-script-${quizId}`);
        script.setAttribute("type", "module");

        script.innerHTML = createWidgetScript(elementId, quizId);
        document.body.appendChild(script);
    }, [elementId, quizId]);

    return (
        <Box
            id={elementId}
            sx={{
                height: "100%",
                width: "100%",
            }}
        />
    );
}

const createWidgetScript = (elementId: string, quizId: string) => `
import widget from "https://hbpn.link/export/pub.js";

widget.create({
    selector: "${elementId}",
    quizId: "${quizId}",
}); 
`;
