import { penaMuiTheme } from "@frontend/kitui";
import { ThemeOptions, createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";




const themeOverrides: ThemeOptions = {
    components: {
        MuiTypography: {
            variants: [
                {
                    props: { variant: "h2-card-number" },
                    style: {
                        fontSize: "80px",
                        lineHeight: "100%",
                        fontWeight: 400,
                    },
                },
            ],
        },
    },
};


declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        "h2-card-number": true;
    }
}
export const theme = createTheme(deepmerge(penaMuiTheme, themeOverrides));

export const cardShadow = "0px 15px 80px rgb(210 208 225 / 70%)";
