import { Box, Link } from "@mui/material";


export default function WidgetShowcase() {

    return (
        <Box
            sx={{
                p: "32px",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
            }}
        >
            <Link href="/widgets/container">Контейнер</Link>
            <Link href="/widgets/banner">Баннер</Link>
            <Link href="/widgets/button">Кнопка</Link>
            <Link href="/widgets/popup">Всплывающий</Link>
            <Link href="/widgets/side">Сбоку</Link>
        </Box>
    );
}
