import { Box } from "@mui/material";
import { useEffect } from "react";
import Lorem from "./Lorem";


const quizId = "0c17353b-55f9-44b5-91d4-a7ffebc5897f";

export default function BannerWidgetShowcase() {

    useEffect(() => {
        if (document.querySelector(`#widget-script-${quizId}`)) return;

        const script = document.createElement("script");
        script.setAttribute("id", `widget-script-${quizId}`);
        script.setAttribute("type", "module");

        script.innerHTML = `
        import { BannerWidget } from "https://hbpn.link/export/pub.js";

        new BannerWidget({
            quizId: "${quizId}",
            position: "bottomright",
        });
        `;
        document.body.appendChild(script);
    }, []);

    return (
        <Box
            sx={{
                p: "16px",
            }}
        >
            <Lorem />
            <Lorem />
            <Lorem />
            <Lorem />
            <Lorem />
            <Lorem />
            <Lorem />
            <Lorem />
            <Lorem />
            <Lorem />
            <Lorem />
            <Lorem />
            <Lorem />
        </Box>
    );
}
