import { Box } from "@mui/material";
import { useEffect } from "react";
import Lorem from "./Lorem";


const quizId = "0c17353b-55f9-44b5-91d4-a7ffebc5897f";

export default function ContainerWidgetShowcase() {

    useEffect(() => {
        if (document.querySelector(`#widget-script-${quizId}`)) return;

        const script = document.createElement("script");
        script.setAttribute("id", `widget-script-${quizId}`);
        script.setAttribute("type", "module");

        script.innerHTML = `
        import { ContainerWidget } from "https://hbpn.link/export/pub.js";
        
        new ContainerWidget({
            selector: "#widget-container",
            quizId: "${quizId}",
        }); 
        `;
        document.body.appendChild(script);
    }, []);

    return (
        <Box
            sx={{
                p: "16px",
            }}
        >
            <Lorem />
            <Lorem />
            <Lorem />
            <Box
                id="widget-container"
                sx={{
                    height: "400px",
                    width: "100%",
                }}
            />
            <Lorem />
            <Lorem />
            <Lorem />
            <Lorem />
            <Lorem />
            <Lorem />
        </Box>
    );
}
